import { lazy } from "react";

export const RoutesList = [
  {
    path: "/builder",
    component: lazy(() => import("./pages/BuilderPage")),
  },
  {
    path: "/demo",
    component: lazy(() => import("./modules/demo/demo")),
  },
  {
    path: "/widget-business/:code",
    component: lazy(() => import("./modules/widget/widget-ui")),
    noBanner: true,
    newPathEnable: true,
  },
  {
    path: "/widget",
    component: lazy(() => import("./modules/widget/widget-ui-redirect")),
    noBanner: true,
  },
  {
    path: "/widget/serviceProviderProfile/:id",
    component: lazy(() => import("./modules/widget/widget-serviceProviderProfile")),
  },
  {
    path: "/widget/otherTimeSlot/:id",
    component: lazy(() => import("./modules/widget/widget-otherTimeSlot")),
    noBanner: true,
  },
  {
    path: "/widget/signup",
    component: lazy(() => import("./modules/widget/widget-signup")),
    noBanner: true
  },
  {
    path: "/widget/medicalForm",
    component: lazy(() => import("./modules/widget/widget-medicalConsentForm")),
    noBanner: true,
  },
  {
    path: "/widget/serviceProviderReviews/:id", //cm
    component: lazy(() => import("./modules/widget/widget-serviceProviderReviews")),
  },
  {
    path: "/widget/404",
    component: lazy(() => import("./modules/widget/widget-404")),
  },
  {
    path: "/widget/401",
    component: lazy(() => import("./modules/widget/widget-401")),
  },
  {
    path: "/widget/paymentPage",
    component: lazy(() => import("./modules/widget/widget-payment")),
  },
  {
    path: "/widget/profile",
    component: lazy(() => import("./modules/widget/widget-viewProfile")),
    noBanner: true,
  },
  {
    path: "/widget/my-Appointments/booking-list",
    component: lazy(() => import("./modules/widget/widget-bookinglist/widget-bookingList")), //cm
  },
  {
    path: "/widget/my-Appointments/psf-booking-list",
    component: lazy(() => import("./modules/widget/widget-bookinglist/widget-psf-bookingList")), //cm
  },
  {
    path: "/widget/my-Appointments/booking-detail/:id",
    component: lazy(() => import("./modules/widget/widget-bookingConfirmation")),
    noBanner: true,
  },
  {
    path: "/widget-business/:code/my-Appointments/psf-booking-detail/:id",
    component: lazy(() => import("./modules/widget/widget-psfBookingDetails")),
    noBanner: true,
    newPathEnable: true,
  },
  {
    path: "/widget/my-Appointments/psf-booking-detail/:id",
    component: lazy(() => import("./modules/widget/widget-ui-redirect").then(module => ({
      default: module.RedirectHelper
    }))),
    noBanner: true
  },
  {
    path: "/widget/my-Appointments/order-medication/:id",
    component: lazy(() => import("./modules/widget/widget-orderMedication")),
    noBanner: true,
  },
  {
    path: "/widget/consultation",
    component: lazy(() => import("./modules/widget/consultation/hcp-consultation")),
    noBanner: true,
  },
  {
    path: "/widget/consultation/otherTimeSlot/:id",
    component: lazy(() => import("./modules/widget/consultation/hcp-otherTimeSlot")),
    noBanner: true,
  },
  {
    path: "/widget/consultation/hcpProfile/:id",
    component: lazy(() => import("./modules/widget/consultation/hcp-profile")),
  },
  {
    path: "/widget/my-Appointments/booking-cancel/:id",
    component: lazy(() => import("./modules/widget/widget-bookinglist/widget-bookingCancel")),
    noBanner: true,
  },
  {
    path: "/widget/documents",
    component: lazy(() => import("./modules/widget/widget-uploadDocument/widget-documentList")), //cm
  },
  {
    path: "/widget/add-Documents",
    component: lazy(() => import("./modules/widget/widget-uploadDocument/widget-addDocuments")), //cm
  },
  {
    path: "/widget/viewEdit-Documents",
    component: lazy(() => import("./modules/widget/widget-uploadDocument/widget-viewEditDocuments")), //cm
  },
  {
    path: "/widget/videoConsultation/:id",
    component: lazy(() => import("./modules/widget/videoConsultation/widget-consultation")),
    noBanner: true,
  },  
  {
    path: "/widget/my-Appointments",
    component: lazy(() => import("./modules/widget/consultationList/widget-myAppoinments")),
    noBanner: true,
  },
  {
    path: "/widget/my-Appointments/consultation-list",
    component: lazy(() => import("./modules/widget/consultationList/widget-consultationList")), //cm
  },
  {
    path: "/widget/consultation/assignServiceToClient",
    component: lazy(() => import("./modules/widget/consultation/assignServiceToClient")), //cm
  },
  {
    path: "/widget/consultation/addClient",
    component: lazy(() => import("./modules/widget/consultation/addClient")), //cm
  },
  {
    path: "/widget/consultation/clientChoose",
    component: lazy(() => import("./modules/widget/consultation/clientChoose")), //cm
  },
  {
    path: "/widget/consultation/clientExists",
    component: lazy(() => import("./modules/widget/consultation/clientExists")), //cm
  },
  {
    path: "/widget/consultation/assignClients",
    component: lazy(() => import("./modules/widget/consultation/assignClients")), //cm
  },
  {
    path: "/widget-verifyMobileMedIvBusiness/:code",
    component: lazy(() => import("./modules/widget/widget-verifyMobileMedIVBusinessService")),
  },
  {
    path: "/widget-verifyMobileMedIvBusiness/:code/:serviceCode",
    component: lazy(() => import("./modules/widget/widget-verifyMobileMedIVBusinessService")),
  },
  {
    path: "/widget/profileEdit",
    component: lazy(() => import("./modules/widget/widget-ProfileEdit")),
    noBanner: true,
  },
  {
    path: "/widget/support",
    component: lazy(() => import("./modules/widget/widget-support")),
    noBanner: true,
  },
  {
    path: "/widget/support/:type",
    component: lazy(() => import("./modules/widget/widget-supportRequest")),
    noBanner: true,
  },
  {
    path: "/widget-business/:code/my-Appointments/consultation-detail/:type/:id",
    component: lazy(() => import("./modules/widget/consultationList/serviceConsultationView")),
    noBanner: true,
    newPathEnable: true,
  },
  {
    path: "/widget/my-Appointments/consultation-detail/:type/:id",
    component: lazy(() => import("./modules/widget/widget-ui-redirect").then(module => ({
      default: module.RedirectHelper
    }))),
    noBanner: true,
  },
  {
    path: "/widget/support/:type",
    component: lazy(() => import("./modules/widget/widget-supportRequest")),
    noBanner: true,
  },
  {
    path: "/widget-business/:code/my-Appointments/consultation-detail/:type/:id",
    component: lazy(() => import("./modules/widget/consultationList/serviceConsultationView")),
    noBanner: true,
    newPathEnable: true,
  },
  {
    path: "/widget/my-Appointments/consultation-detail/:type/:id",
    component: lazy(() => import("./modules/widget/widget-ui-redirect").then(module => ({
      default: module.RedirectHelper
    }))),
    noBanner: true,
  },
  {
    path: "/widget/procedures/:id", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/clientProcedures")),
  },
  {
    path: "/widget/servicePackForms/consentForImages", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/consentForImagesForm")),
  },
  {
    path: "/widget/servicePackForms/hipaaConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/hippaConsentForm")),
  },
  {
    path: "/widget/servicePackForms/individualMediaRelease", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/individualMediaReleaseForm")),
  },
  {
    path: "/widget/servicePackForms/hipaaAuthorizationform", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/hippaAuthorizationform")),
  },
  {
    path: "/widget/servicePackForms/nadInformedConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/nadConsentForm")),
  },
  {
    path: "/widget/servicePackForms/nadIntakeForm", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/nadIntakeForm")),
  },
  {
    path: "/widget/servicePackForms/carnitineIntakeForm", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/carnitineIntakeForm")),
  },
  {
    path: "/widget/servicePackForms/ivBoosterConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/ivBoosterConsentForm")),
  },
  {
    path: "/widget/servicePackForms/covidConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/covidConsentForm")),
  },
  {
    path: "/widget/servicePackForms/scleroTherapyConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/seleroTherapyConsentForm")),
  },
  {
    path: "/widget/servicePackForms/polidocanolInjectionInformedConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/polodocanolInjectionInformedConsentForm")),
  },
  {
    path: "/widget/servicePackForms/infusionConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/infusionConsent")),
  },
  {
    path: "/widget/servicePackForms/microneedlineConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/microneedlingConsent")),
  },
  {
    path: "/widget/servicePackForms/prpConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/prpInformedConsent")),
  },
  {
    path: "/widget/servicePackForms/dermalFillerConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/darmalFillerConsent")),
  },
  {
    path: "/widget/servicePackForms/patientAttestation", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/patientAttestationForm")),
  },
  {
    path: "/widget/servicePackForms/informedConsentForNeurotoxin", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/informedConsentForm")),
  },
  {
    path: "/widget/servicePackForms/neurotoxinInjectablesIntake", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/neurotoxinInjectBriefIntake")),
  },
  {
    path: "/widget/servicePackForms/mesotherapyConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/mesotherapyConsentForm")),
  },
  {
    path: "/widget/servicePackForms/questionnaireForNeurotoxinInjections", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/questionnaireForNeurotoxinInjectionsForm")),
  },
  {
    path: "/widget/servicePackForms/zofran", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/zofranIntakeForm")),
  },
  {
    path: "/widget/servicePackForms/ivPatientIntakeForm", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/ivPatientIntakeForm")),
  },
  {
    path: "/widget/servicePackForms/ivGoodFaithExam", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/ivGoodFaithExamForm")),
  },
  {
    path: "/widget/servicePackForms/fillerMedicalHistory", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/fillerMedicalHistoryForm")),
  },
  {
    path: "/widget/servicePackForms/toradol", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/toradolForm")),
  },
  {
    path: "/widget/servicePackForms/dripbar/nadInformedConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/nadInformedConsentFormDripbar")),
  },
  {
    path: "/widget/servicePackForms/semaglutideInformedConsent", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/semaglutideInformedConsent")),
  },
  {
    path: "/widget/servicePackForms/semaglutidescreeningtool", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/semaglutideScreeningTool")),
  }, 
  {
    path: "/widget/servicePackForms/coQ10ScreeningTool", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/coQ10ScreeningTool")),
    },
    {
        path: "/widget/servicePackForms/aLAConsent", //cm
        component: lazy(() => import("./modules/widget/servicePackForms/aLAConsentFormDripbar")),
    
  },
  {
    path: "/widget/servicePackForms/IVPepcidScreeningToolForm", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/ivPepcidScreeningTool")),
  },
  {
    path: "/widget/servicePackForms/weightlossquestionnaireforphytmedspa", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/weight-loss-questionnaire")),
  },
  {
    path: "/widget/servicePackForms/informedconsentformforphytmedspa", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/informed-consent")),
  },
  {
    path: "/widget/servicePackForms/hipaaformforphytmedspa", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/hipaa-form")),
  },
  {
    path: "/widget/servicePackForms/returnrefundpolicyforphytmedspa", //cm
    component: lazy(() => import("./modules/widget/servicePackForms/return-and-refund-policy")),
  },
];
