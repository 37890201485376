import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export const ButtonComponent = (props) => {
  const {
    labelId,
    moduleName,
    className,
    onClick,
    icon,
    type,
    category,
    isGrayOut,
    isIcon,
    disabled,
  } = props;

  const [buttonBackground, setButtonBackground] = useState(
    isGrayOut ? "outline-secondary" : category || "primary"
  );

  const onMouseEnter = () => {
    if (isGrayOut) setButtonBackground(category || "primary");
  };

  const onMouseLeave = () => {
    if (isGrayOut) setButtonBackground("outline-secondary");
  };

  return (
    <>
      {!isIcon ? (
        <button
          disabled={disabled}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          type={type ? type : "button"}
          className={`btn btn-${buttonBackground} ${
            icon ? "btn-sm ml-1" : ""
          } ${className} cursor-pointer`}
          onClick={onClick}
        >
          {icon ? (
            <>
              <i className={`fas ${icon} p-0 mr-2`} />
              <FormattedMessage
                id={labelId ? labelId : "button.add"}
                values={{ entity: moduleName }}
              />
            </>
          ) : (
            <FormattedMessage id={labelId ? labelId : "button.add"} />
          )}
        </button>
      ) : (
        <button
          disabled={disabled}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          type={type ? type : "button"}
          className={`btn btn-${buttonBackground} btn-sm ${className}`}
          onClick={onClick}
        >
          <i className={`fas ${icon} p-0`} />
        </button>
      )}
    </>
  );
};
