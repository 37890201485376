import * as Msal from "@azure/msal-browser";
import { actionTypes } from "../app/modules/widget/_redux/widgetRedux";
import { actionTypes as authActionTypes } from "../app/modules/Auth/_redux/authRedux";
import store from "../redux/store";
import {
  msalConfig,
  loginRequest,
  silentRequest,
  tokenRequest,
  resetPasswordRequest,
  passwordReset,
  changePasswordRequest,
  signInPolicy,
} from "./authConfig";
import LogRocket from "logrocket";
import { decreaseCounter, increaseCounter } from "./apiCallsProvider";

// Create the main msalApp instance
// configuration parameters are located at authConfig.js
const msalApp = new Msal.PublicClientApplication(msalConfig);

let accountId = "";
let accessToken = "";

msalApp
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((error) => {
    console.error(error);

    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
      try {
        msalApp.loginRedirect(resetPasswordRequest);
      } catch (err) {
        console.error(err);
      }
    }
    if (error.errorMessage.indexOf("AADB2C90091") > -1) {
      store.dispatch({
        type: authActionTypes.Login,
        payload: {
          isSigninInProgress: false,
        },
      });
    }
  });

function selectAccount() {
  const account = store.getState().auth.user;
  // If this condition is true it means the request is not coming from iFrame.
  if (window.location === window.parent.location) {
    const currentAccounts = msalApp.getAllAccounts()
    if (currentAccounts.length === 0 && account) currentAccounts[0] = account;
    const latestUser = currentAccounts?.find(
      (item) =>
        item.idTokenClaims["tfp"]?.toLowerCase() === signInPolicy?.toLowerCase()
    );
    accountId = latestUser?.homeAccountId;

    if (!currentAccounts || currentAccounts.length < 1 || !latestUser) {
      console.warn("User not found.");
      store.dispatch({
        type: authActionTypes.Login,
        payload: {
          user: null,
          authToken: "",
          userName: "",
          isSigninInProgress: false,
        },
      });
      store.dispatch({
        type: actionTypes.SetUserTypeDetail,
        payload: {
          userDetailId: 0,
        },
      });
      return;
    } else if (currentAccounts.length > 1) {
      console.warn("multiple user account detected.");
      store.dispatch({
        type: authActionTypes.Login,
        payload: {
          user: latestUser,
          authToken: accessToken,
          userName: latestUser?.username,
          isSigninInProgress: false,
        },
      });
    } else if (currentAccounts.length === 1) {
      store.dispatch({
        type: authActionTypes.Login,
        payload: {
          user: latestUser,
          authToken: accessToken,
          userName: latestUser?.username,
          isSigninInProgress: false,
        },
      });
    }
  }
}

function handleResponse(response) {
  if (response !== null) {
    if (response.accessToken != null) {
      accessToken = response.accessToken;
    }
    if (
      response.idTokenClaims["acr"]?.toLowerCase() ===
      passwordReset?.toLowerCase()
    ) {
      signIn();
    } else {
      selectAccount();
    }
  } else {
    selectAccount();
  }
}

export const signIn = async (signupSuccess = false, businessLogo = null) => {
  let loginRequestVar = loginRequest;

  if (businessLogo != null){
    loginRequestVar = {...loginRequest , state : businessLogo }
  }

  msalApp.loginRedirect(loginRequestVar);
  store.dispatch([
    {
      type: authActionTypes.UpdateSignupSuccess,
      payload: {
        signupSuccess
      },
    },
    {
      type: authActionTypes.Login,
      payload: {
        isSigninInProgress: true,
      },
    }
]);
};

export function signOut() {
  // Choose which account to logout from by passing a username.
  localStorage.clear();
  sessionStorage.clear();
  increaseCounter()
  store.dispatch({
    type: authActionTypes.Login,
    payload: {
      user: null,
      authToken: "",
      userName: "",
      isSigninInProgress: false,
    },
  });
  store.dispatch({
    type: actionTypes.SetUserTypeDetail,
    payload: {
      userDetailId: 0,
    },
  });
  const logoutRequest = {
    account: msalApp.getAccountByHomeId(accountId),
  };
  msalApp.logoutRedirect(logoutRequest);
  decreaseCounter();
  LogRocket.startNewSession();
}

export function resetPassword() {
  msalApp.loginRedirect(resetPasswordRequest);
}
export function changePassword() {
  msalApp.loginRedirect(changePasswordRequest);
}

export const acquireAccessToken = async () => {
  await msalApp.handleRedirectPromise();
  silentRequest.account = msalApp.getAccountByHomeId(accountId);
  return msalApp.acquireTokenSilent(silentRequest).catch((error) => {
    console.warn(
      "silent token acquisition fails. acquiring token using interactive method"
    );
    if (error) {
      // fallback to interaction when silent call fails
      tokenRequest.account = msalApp.getAccountByHomeId(accountId);
      return msalApp.acquireTokenRedirect(tokenRequest);
    } else {
      console.warn(error);
    }
  });
};
