import React from "react";
import "../../../_metronic/_assets/sass/pages/error/error-4.scss";
import WarningButtonSvg from "../../assets/svg/Warning";

const WidgetAlert = ({text, subText, isButton, btnTitle, onClick}) => {
  return (
    <div className="d-flex flex-column align-items-center widget-alert-backdrop" >
      <div className="alert-container">
        <div className="mx-auto"><WarningButtonSvg /></div>
        {text && <div className="alert-text">{text}</div>}
        {subText && <div className="alert-sub-text">{subText}</div>}
        {isButton &&<div className="alert-button-container">
          <button
            type="button"
						className={`btn cm-btn-widgetAlert `}
            onClick={onClick}
          >{btnTitle}</button>
        </div>}
      </div>
    </div>
  );
}

export default WidgetAlert;
