import React from "react";
import PropTypes from "prop-types";
import { ButtonComponent } from "../components/buttonComponent";
import ModalPopUp from "../modules/widget/ModalPopUp";
export const ConfirmationPopup = (props) => {
  return (
    <ModalPopUp
      show={props.show}
      headerText={props.title}
      isLongTitle={props.isLongTitle}
      handleCloseModal={props.onHide}
      isFooter={true}
      ariaLabelledby={"example-modal-sizes-title-lg"}
      body={
        <>
          {props.message}
          {props.detailedMessage && (
            <p className="font-italic text-muted pt-2">{props.detailedMessage}</p>
          )}
          <div>{props?.inputForm && props?.inputForm()}</div>
        </>
      }
      isBottomSheetRequired = {props.isBottomSheetRequired}
      footerComponent={
        <>
          {!props?.hideCancel && (
            <ButtonComponent
              labelId={props.cancelButtonTitle || "button.cancel"}
              category="light"
              className="btn-elevate"
              onClick={props.onHide}
            />)}
          <ButtonComponent
            labelId={props.actionButtonTitle}
            className="ml-3 btn-elevate"
            onClick={props.actionMethod}
          />
        </>
      }
    />
  );
};

ConfirmationPopup.prototype = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  cancelButtonTitle: PropTypes.func.isRequired,
  actionButtonTitle: PropTypes.string.isRequired,
  actionMethod: PropTypes.func.isRequired,
};
