import React from "react";

const WarningButtonSvg = ({props}) => {
  return (
    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M44.7062 34.4907C44.7062 34.646 44.6967 34.799 44.6781 34.9492H44.7062L43.6425 48.1804C43.6186 48.4781 43.5988 48.7978 43.5783 49.1301C43.428 51.5614 43.2363 54.6622 41.0698 54.6622C38.9033 54.6622 38.7116 51.5614 38.5613 49.1301C38.5407 48.7978 38.521 48.4781 38.4971 48.1804L37.4333 34.9492H37.4615C37.4429 34.799 37.4333 34.646 37.4333 34.4907C37.4333 32.4652 39.0614 30.8232 41.0698 30.8232C43.0781 30.8232 44.7062 32.4652 44.7062 34.4907Z" fill="#252527"/>
      <path d="M44.2517 59.9343C44.2517 61.5801 42.8271 62.9142 41.0698 62.9142C39.3125 62.9142 37.8879 61.5801 37.8879 59.9343C37.8879 58.2886 39.3125 56.9545 41.0698 56.9545C42.8271 56.9545 44.2517 58.2886 44.2517 59.9343Z" fill="#252527"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M46.9475 12.6834C44.1342 7.74061 37.0509 7.78038 34.2924 12.7545L6.93591 62.0843C4.22443 66.9737 7.72873 73 13.2834 73H68.7163C74.3069 73 77.8071 66.9033 75.0239 62.0132L46.9475 12.6834ZM35.8793 13.6497C37.9481 9.91907 43.2607 9.88923 45.3706 13.5964L73.447 62.9261C75.5344 66.5937 72.9093 71.1662 68.7163 71.1662H13.2834C9.11741 71.1662 6.48918 66.6465 8.52278 62.9795L35.8793 13.6497Z" fill="#252527"/>
    </svg>
  )
}

export default WarningButtonSvg;

