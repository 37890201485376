/* eslint-disable jsx-a11y/anchor-is-valid*/
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { DataConstant } from "../../constants/dataConstant";
import { actionTypes } from "./_redux/widgetRedux";
import { actionTypes as consultationActionTypes } from "./_redux/consultationRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { signOut, signIn } from "../../../utils/authProvider";
import { isValidPhoneNumber, returnToken } from "../../../utils/helper";
import ReadMoreText from 'read-more-less-react';
import 'read-more-less-react/dist/index.css'
import { toAbsoluteUrl } from "../../../_metronic/_helpers/AssetsHelpers";
import SVG from "react-inlinesvg";
import styles from "./widget-businessProfile.module.css";
import styles2 from "./widgetLayout.module.css";
import MenuButtonSvg from "../../assets/svg/MenuButton";
const { REACT_APP_IMAGEURL } = process.env;

const IListItem = ({index, item, handleListItemClick, showMore=false}) => {
  return (
    item?.text ? 
    <div
      className={styles2.listItem}
      onClick={() => handleListItemClick(index)}
    >
      <span className={styles2.listIcon}>
        {item?.icon}
      </span>
      <span className={styles2.listText}>
        {!item?.tel ? <ReadMoreText
          lines={showMore ? 3 : 100}
          text={item?.text}
        /> : <a href={`tel:${item?.text}`} className="link">{item?.text}</a>}
      </span>
    </div> : null
  )
}


const BusinessProfile2 = ({ businessDetail }) => {
  const [expandedItem, setExpandedItem] = useState(null); // State to track expanded item

  const handleListItemClick = (index) => {
    if (expandedItem === index) {
      setExpandedItem(null); // Collapse if already expanded
    } else {
      setExpandedItem(index); // Expand if not already expanded
    }
  };

  const listItems = [
    {
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Home/businessDescription.svg")} />,
      text: businessDetail?.businessDescription
    },
    {
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Home/businessLocation.svg")} />,
      text: businessDetail?.isCallOutService ? [businessDetail?.businessState, businessDetail?.businessCountry].filter(a => a).join(", ") :businessDetail?.businessAdddress
    },
  ];

  if(isValidPhoneNumber(businessDetail?.businessPhoneNumber)){
    listItems.push({
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Home/businessPhone.svg")} />,
      text: businessDetail?.businessPhoneNumber,
      tel: true
    });
  }

  return (
    <div className={styles2.leftInnerContainer}>
      

      {businessDetail?.businessId ? <>
        <div className={styles2.detailsImageContainer}>
          <img src={businessDetail?.businessProfilePic ? businessDetail?.businessProfilePic : REACT_APP_IMAGEURL + DataConstant.media.defaultUserPic} alt="Business Profile" />
        </div>
        <ul className={styles2.listContainer}>
          <h3 className={`text-dark text-capitalize text-left ${styles2.header}`}>
            {businessDetail?.businessName}
          </h3>
          {listItems.map((item, index) => (
            <IListItem key={index} index={index} item={item} handleListItemClick={handleListItemClick} />
          ))}
        </ul>
      </> : null}
    </div>
  );

};
export default BusinessProfile2;

export const WidgetNavigation = ({history, businessDetail}) => {
  const dispatch = useDispatch();

  const goToHomePage = () => {
    dispatch([
      {
        type: actionTypes.ClearBookingDetail,
      },
      {
        type: consultationActionTypes.setClearConsultationDetail,
      },
      {
        type: actionTypes.SetWidgetTab,
        payload: {
          currentTab: DataConstant.tabs.widgetMain
        },
      }
    ]);
    history.push(`${returnToken()}`);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles2.outerContainer}>
      <nav className={styles2.nav}>
        <div className={styles2.whiteLabelIcon} onClick={goToHomePage}>
          {businessDetail?.businessProfilePic ? <img src={businessDetail?.businessProfilePic} alt="Business Profile" /> : <img
            src={REACT_APP_IMAGEURL +
              DataConstant.media.defaultprofilepic}
            alt="default profile image"
          />}
        </div>
        <ul className={styles2.navLinksContainer}>
          <NavLinks history={history} />
        </ul>
        <LoginButtons history={history} />
      </nav>
      <div className={`${styles2.nav} ${styles2.menuButtonContainer}`}>
        <button onClick={handleOpen} className={styles2.menuButton}>
          {/* <i class={`fas fa-bars ${styles2.icon}`}></i> */}
          <MenuButtonSvg/>
        </button>
        <LoginButtons history={history} mobileHeader={true} />
      </div>
      <div className={styles2.sheetContainer}>
        <div className={`${styles2.sideSheet} ${isOpen ? styles2.sideSheetOpen : ''}`} onClick={handleClose}>
          <div className={styles2.sideSheetModal} onClick={(e) => e.stopPropagation()}>
            <div className={styles2.sideSheetModalContainer}>
              <button onClick={handleClose} className={styles2.closeButton}>
                <i class={`fas fa-times ${styles2.icon}`}></i>
              </button>
              <div className="d-flex flex-row gap-12 mt-10">
                <div className={styles2.mSideImageContainer}>
                  <img src={businessDetail?.businessProfilePic ? businessDetail?.businessProfilePic : REACT_APP_IMAGEURL + DataConstant.media.defaultUserPic} alt="Business Profile" />
                </div>
                <div className="flex-1 d-flex flex-column justify-content-between py-2">
                  <h3 className="text-dark text-capitalize text-left" style={{fontSize: "16px"}}>
                    {businessDetail?.businessName}
                  </h3>
                </div>
              </div>

              <ul className={styles2.mNavLinksContainer}>
                <NavLinks history={history} handleClose={handleClose} />
              </ul>
              <div>
                <LoginButtons history={history} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const MobileBusinessProfile = ({ showBusinessInMobile=false }) => {
  const widgetDetail = useSelector((state) => state.widget);
  const businessDetail = widgetDetail?.businessDetail;
  const [expandedItem, setExpandedItem] = useState(null);
  const [isArrowCollapsed, setIsArrowCollapsed] = useState(true);

  const listItems = [
    {
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Home/businessDescription.svg")} />,
      text: businessDetail?.businessDescription
    },
    {
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Home/businessLocation.svg")} />,
      text: businessDetail?.isCallOutService ? [businessDetail?.businessState, businessDetail?.businessCountry].filter(a => a).join(", ") :businessDetail?.businessAdddress
    },
  ];

  if(isValidPhoneNumber(businessDetail?.businessPhoneNumber)){
    listItems.push({
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Home/businessPhone.svg")} />,
      text: businessDetail?.businessPhoneNumber,
      tel: true
    });
  }

  const handleListItemClick = (index) => {
    if (expandedItem === index) {
      setExpandedItem(null); // Collapse if already expanded
    } else {
      setExpandedItem(index); // Expand if not already expanded
    }
  };

  return (
    <>
      { showBusinessInMobile && businessDetail?.businessId ?
        <>
          <div className={styles2.mDetailsImageContainer}>
            <img src={businessDetail?.businessProfilePic ? businessDetail?.businessProfilePic : REACT_APP_IMAGEURL + DataConstant.media.defaultUserPic} alt="Business Profile" />
          </div>
          <div className={styles2.businessMobileView}>
            <div className={styles2.mHeadingWithButton}>
              <div className={styles2.headerContainer}>
                <h3 className={`text-dark text-capitalize text-left ${styles2.header}`}>
                  {businessDetail?.businessName}
                </h3>
                <button
                  className={`btn ${styles.detailsToggle}`}
                  onClick={() => setIsArrowCollapsed(a => !a)}
                >
                  <i className="fas">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/rightArrow.svg")} className={isArrowCollapsed ? styles2.visible : styles2.invisible} />
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/downArrow.svg")} className={isArrowCollapsed ? styles2.invisible : styles2.visible} />
                  </i>
                </button>
              </div>
            </div>
            <div className={`${styles2.detailsTray} ${isArrowCollapsed ? styles2.inactiveTray : styles2.activeTray}`}>
              <div className={styles2.bgtemp}>
                {listItems.map((item, index) => (
                  <IListItem key={index} index={index} item={item} handleListItemClick={handleListItemClick} />
                ))}
              </div>
            </div>
          </div>
        </> : null
      }
    </>
  )
}

const LoginButtons = ({ history, mobileHeader }) => {
  const dispatch = useDispatch();

  
  const { isAuthorized, isSigninInProgress, businessProfilePic } = useSelector(
    ({ auth, widget }) => ({
      isAuthorized: auth.user != null,
      isSigninInProgress: auth.isSigninInProgress,
      businessProfilePic : widget?.businessDetail?.businessProfilePic ?? null,
    }),
    shallowEqual
  );

  const logout = () => {
    dispatch([
      {
        type: consultationActionTypes.setClearConsultationDetail,
      },
      {
        type: actionTypes.ClearBookingDetail,
      },
      {
        type: actionTypes.ClearUserDetail,
      }
    ]);
    signOut();
  };

  return (
    <div className={mobileHeader ? styles2.mobileHeaderButton : styles2.loginContainer}>
      {isAuthorized && isSigninInProgress === false ? (
        <button className="nav-btn btn-clear" onClick={logout}>Logout</button>
      ) : (
        <>
          <button className="nav-btn btn-clear" onClick={() => {
              signIn(false, businessProfilePic);
            }}
          >
            Login
            {(isSigninInProgress) && <div class="spinner-border spinner-border-sm cm-text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>}
          </button>
          <button className="nav-btn btn-primary" onClick={() => history.push(`/widget/signup`)}>Sign up</button>
        </>
      )}
    </div>
  )
}

const NavLinks = ({ history, handleClose }) => {
  const location = useLocation();

  const dispatch = useDispatch();


  const goToHomePage = () => {
    dispatch([
      {
        type: actionTypes.ClearBookingDetail,
      },
      {
        type: consultationActionTypes.setClearConsultationDetail,
      },
      {
        type: actionTypes.SetWidgetTab,
        payload: {
          currentTab: DataConstant.tabs.widgetMain
        },
      },
      
    ]);
    handleClose && handleClose();
    history.push(`${returnToken()}`);
  };

  const { isAuthorized, isSigninInProgress } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      isSigninInProgress: auth.isSigninInProgress,
    }),
    shallowEqual
  );

  const onAppoinmentClick = () => {
    dispatch([
      {
        type: actionTypes.setConsultationListPage,
        payload: {
          isBackFromConsultationListPage: false,
        },
      },
      {
        type: consultationActionTypes.setClearConsultationDetail,
      },
      {
        type: actionTypes.SetWidgetTab,
        payload: {
          currentTab: DataConstant.tabs.widgetMain
        },
      },
      
    ]);
    handleClose && handleClose();
    history.push(`${returnToken("my-Appointments")}`);
  }

  const onProfileClick = () => {
    handleClose && handleClose();
    history.push(`${returnToken("profile")}`)
  }

  const onSupportClick = () => {
    history.push(`${returnToken("support")}`)
  }

  const navs = [
    {
      text: "Home",
      to: "/widget-business",
      onClick: goToHomePage
    }
  ]

  const authNavs = [
    {
      text: "Home",
      to: "/widget-business",
      onClick: goToHomePage
    },
    {
      text: "Profile",
      to: "/widget/profile",
      onClick: onProfileClick
    },
    {
      text: "My appointments",
      to: "/my-Appointments",
      onClick: onAppoinmentClick
    },
    {
      text: "Support",
      to: "/widget/support",
      onClick: onSupportClick
    },
  ]

  // Check if the link matches the current URL

  return (
    isAuthorized && isSigninInProgress === false ? <>
      {authNavs?.map((nav, index) => {
        const isActive = nav.to === "/widget-business" ? location?.pathname?.includes(nav.to) && location?.pathname?.split("/")?.length <= 3 : location?.pathname?.includes(nav.to);
        return (
          <li key={index} className={`${styles2.navLink} ${isActive ? styles2.activeLink : styles2.inactiveLink}`} onClick={nav.onClick}>{nav?.text}</li>
        )
      })}
    </> : (
      <>
        {navs?.map((nav, index) => {
          const isActive = location?.pathname?.includes(nav.to);
          return (
            <li key={index} className={`${styles2.navLink} ${isActive ? styles2.activeLink : styles2.inactiveLink}`} onClick={nav.onClick}>{nav?.text}</li>
          )
        })}
      </>
    )
  );
}
